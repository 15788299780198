import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import SocialIcons from "../SocialIcons";

import * as S from "./styles";

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author {
              name
            }
          }
        }
      }
    `
  );

  const { name } = site.siteMetadata.author;

  return (
    <S.Footer>
      <S.Copyright>
        © {new Date().getFullYear()} {name}
      </S.Copyright>
      <SocialIcons />
    </S.Footer>
  );
};

export default Footer;
