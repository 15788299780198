import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FaLinkedinIn, FaMediumM, FaDev } from "react-icons/fa";

import * as S from "./styles";

const SocialIcons = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              medium
              linkedin
              dev
            }
          }
        }
      }
    `
  );

  const { medium, linkedin, dev } = site.siteMetadata.social;

  return (
    <S.Social>
      <a
        href={linkedin}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visit my LinkedIn profile"
      >
        <FaLinkedinIn />
      </a>
      <a
        href={medium}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visit my Medium profile"
      >
        <FaMediumM />
      </a>
      <a
        href={dev}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visit my DEV profile"
      >
        <FaDev />
      </a>
    </S.Social>
  );
};

export default SocialIcons;
