import styled from "styled-components";
import { Link } from "gatsby";

export const Nav = styled.nav`
  height: 50px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  font-size: 1.25rem;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const Pages = styled.div`
  display: flex;
  align-items: center;

  button {
    border: none;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 1.75rem;
    cursor: pointer;
    outline: none;
    color: var(--text-color);
    margin-left: 1rem;

    @media (max-width: 480px) {
      font-size: 1.25rem;
    }
  }
`;

export const NavItem = styled(Link)`
  margin: 0 0.75rem;
  border-bottom: ${props => (props.selected ? "2px solid" : "none")};
  font-weight: ${prop => (prop.selected ? "bold" : "normal")};
`;

export const NoTheme = styled.div`
  height: 28px;
  width: 28px;
  margin-left: 1rem;

  @media (max-width: 480px) {
    height: 20px;
    width: 20px;
  }
`;
