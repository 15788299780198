import React from "react";
import PropTypes from "prop-types";

import GlobalStyles from "../../GlobalStyles";
import Nav from "../Nav";
import Footer from "../Footer";

import * as S from "./styles";

const Layout = ({ children, pathname }) => {
  return (
    <S.Container>
      <GlobalStyles />
      <Nav pathname={pathname} />
      <main>{children}</main>
      <Footer />
    </S.Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string,
};

export default Layout;
