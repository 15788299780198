import styled from "styled-components";

export const Footer = styled.footer`
  margin-top: 7rem;
  border-top: 1px solid var(--border-color);
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const Copyright = styled.p`
  margin: 0;
`;
