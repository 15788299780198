import React from "react";
import PropTypes from "prop-types";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import { FaMoon, FaSun } from "react-icons/fa";

import Logo from "../../../content/assets/logo/FD-Logo.svg";
import * as S from "./styles";

const changeTheme = (theme, toggleTheme) => {
  if (!theme) return <S.NoTheme />;
  let icon;
  let newTheme;
  if (theme === "light") {
    newTheme = "dark";
    icon = <FaMoon />;
  } else {
    newTheme = "light";
    icon = <FaSun />;
  }
  return (
    <button aria-label="Change theme" onClick={() => toggleTheme(newTheme)}>
      {icon}
    </button>
  );
};

const Nav = ({ pathname }) => (
  <S.Nav>
    <S.NavItem aria-label="Home" to="/">
      <Logo style={{ width: "30px" }} />
    </S.NavItem>
    <S.Pages>
      <S.NavItem to="/posts" selected={pathname && pathname.includes("/posts")}>
        Posts
      </S.NavItem>
      <S.NavItem to="/about" selected={pathname && pathname.includes("/about")}>
        About
      </S.NavItem>
      <ThemeToggler>
        {({ theme, toggleTheme }) => changeTheme(theme, toggleTheme)}
      </ThemeToggler>
    </S.Pages>
  </S.Nav>
);

Nav.propTypes = {
  pathname: PropTypes.string,
};

export default Nav;
