import styled from "styled-components";

export const Social = styled.div`
  display: flex;

  * {
    margin-right: 1rem;
    width: 1.5rem;
    height: 1.5rem;

    @media (max-width: 480px) {
      width: 1rem;
      height: 1rem;
    }
  }
`;
