import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    --bg-color: rgb(250, 250, 250);
    --text-color: rgb(34, 36, 38);
    --border-color: rgb(204, 204, 204);
    --text-muted-color: rgb(51, 51, 51);
    --link-color: rgb(5, 82, 181);
    --link-hover-color: rgb(1, 51, 125);
    --code-color: rgba(0, 0, 0, 0.08);

    font-family: 'inter', sans-serif;
    color: var(--text-color);
    background-color: var(--bg-color);
    font-size: 1.25rem;
    transition: background-color 0.3s;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  body.dark {
    --bg-color: rgb(34, 36, 38);
    --text-color: rgb(250, 250, 250);
    --border-color: rgb(204, 204, 204);
    --text-muted-color: rgb(225,225,225);
    --link-color: rgb(150,196,252);
    --link-hover-color: rgb(92,164,251);
    --code-color: rgba(255, 255, 255, 0.12);
  }

  .logo {
    fill: var(--text-color);
    fill-rule: evenodd;
  }

  li {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: var(--link-color);
    transition: 0.3s;
    font-size: 1.25rem;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  a:hover {
    color: var(--link-hover-color)
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 3.25rem;

    @media (max-width: 480px) {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: 2rem;

    @media (max-width: 480px) {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-size: 1.5rem;

    @media (max-width: 480px) {
      font-size: 1.15rem;
    }
  }

  p, input, textarea {
    font-family: 'inter', sans-serif;
    font-size: 1.25rem;
    line-height: 32px;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  input, textarea {
    box-sizing: border-box;
  }

  :not(pre) > code[class*="language-"] {
    padding: .1em .3em;
    border-radius: .3em;
    white-space: normal;
    background-color: var(--code-color);
    color: inherit;
    font-family: 'inter', sans-serif;
    font-size: 1.25rem;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  pre {
    font-size: 1rem !important;

    @media (max-width: 480px) {
      font-size: 0.85rem !important;
    }

    code {
      white-space: pre-wrap !important;
    }
  }

  figure {
    text-align: center;

    img, video {
      max-width: 100%;
    }
  }

  .image-caption {
    text-align: center;
    font-style: italic;
    color: var(--text-muted-color);
    font-size: 85%;
    margin-top: 0.75rem;
  }

  blockquote {
    font-style: italic;
    color: var(--text-muted-color);
    margin: 2.5rem 1rem;
    padding: 0 0.5rem;
    border-left: 2px solid;
    border-color: var(--border-color);
  }
`;

export default GlobalStyles;
